export const isSmallerThan = (version1, version2) => {
  const v1 = version1.split('.').map(Number);
  const v2 = version2.split('.').map(Number);

  const length = Math.max(v1.length, v2.length);

  return [ ...Array(length) ].reduce((result, _, i) => {
    const segment1 = v1[i] ?? 0;
    const segment2 = v2[i] ?? 0;

    if (result !== null) return result;

    if (segment1 < segment2) return true;

    if (segment1 > segment2) return false;

    return null;
  }, null) ?? false;
};
