import { InfoCircle } from 'react-iconly';
import { Button, Modal } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';



const PollingNotificationModal = ({ title, isActiveModal, toggleModal }) => {
  return (
    <Modal
      title={
        <div className='da-d-flex-center'>
          <InfoCircle set="curved" />
          &nbsp;
          {title}
        </div>
      }
      width={500}
      centered
      closable
      destroyOnClose
      visible={isActiveModal}
      onCancel={() => toggleModal(false)}
      footer={[
        <Button
          key="submit"
          type="primary"
          onClick={() => toggleModal(false)}
        >
          OK
        </Button>,
      ]}
    >
      <div>Please wait...</div>
      <div>The request is queued and the average execution is up to 5 minutes.</div>
      <div>The data is updated approximately every 10 minutes.</div>
    </Modal>
  );
};

PollingNotificationModal.propTypes = {
  title: PropTypes.string.isRequired,
  isActiveModal: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
};

export default PollingNotificationModal;
