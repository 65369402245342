import { Descriptions, Divider } from 'antd';
import capitalize from 'lodash/capitalize';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import isNil from 'lodash/isNil';
import { getValueOrPlaceholder } from '../../../../lib/getValueOrPlaceholder';
import { getNotEmptyDescriptionItem } from '../../../../lib/getNotEmptyDescriptionItem';
import CollapsibleCardWr from '../../../../components/CollapsibleCardWr';
import { LAT_TYPES_TRANSLATIONS } from '../../../AbTests/forms/FormCreateEditAbTest/FormCreateEditAbTest.const';
import { EMPTY_VALUE_PLACEHOLDER } from '../../../../const/system';



const preparedArrayMap = (arrValues) => {
  return (
    !isEmpty(arrValues) ? arrValues.map((item) => {
      return (
        <div key={item}>{item}</div>
      );
    }) : EMPTY_VALUE_PLACEHOLDER
  );
};

const ConfigurationTargetDetailsConditionsCard = ({ index, condition, isLoading }) => {
  return (
    <CollapsibleCardWr
      title={`Target condition #${index + 1}`}
      isLoading={isLoading}
      keyValue={`Target condition #${index + 1}`}
      isCollapsible
    >
      <Descriptions bordered column={1}>
        {getNotEmptyDescriptionItem(condition?.condition_type, 'Condition type:')}
        {!isEmpty(condition?.countries) && (
          <Descriptions.Item label="Countries:">
            {condition.countries.join(', ').toUpperCase()}
          </Descriptions.Item>
        )}
        {!isEmpty(condition?.device_types) && (
          <Descriptions.Item label="Device types:">
            {condition.device_types.map((item) => capitalize(item)).join(', ')}
          </Descriptions.Item>
        )}
        {!isEmpty(condition?.device_manufactures) && (
          <Descriptions.Item label="Device manufactures:">
            {condition.device_manufactures.map((item) => capitalize(item)).join(', ')}
          </Descriptions.Item>
        )}
        {!isEmpty(condition?.device_models) && (
          <Descriptions.Item label="Device models:">
            {preparedArrayMap(condition.device_models)}
          </Descriptions.Item>
        )}
        {!isEmpty(condition?.ram) && (
          <Descriptions.Item label="Ram (Mb):">
            <>
              <div>{`Min: ${getValueOrPlaceholder(condition.ram?.min)}`}</div>
              <div>{`Max: ${getValueOrPlaceholder(condition.ram?.max)}`}</div>
              {!isEmpty(condition?.ram?.list) && (
                <div>{`List: ${condition.ram.list.map((item) => item).join(', ')}`}</div>
              )}
            </>
          </Descriptions.Item>
        )}
        {!isEmpty(condition?.device_performance) && (
          <Descriptions.Item label="Device performance:">
            <>
              <div>{`Min: ${getValueOrPlaceholder(condition.device_performance?.min)}`}</div>
              <div>{`Max: ${getValueOrPlaceholder(condition.device_performance?.max)}`}</div>
              {!isEmpty(condition.device_performance?.list) && (
                <div>{`List: ${condition.device_performance.list.map((item) => item).join(', ')}`}</div>
              )}
            </>
          </Descriptions.Item>
        )}
        {!isEmpty(condition?.languages) && (
          <Descriptions.Item label="Languages:">
            {condition.languages.map((item) => capitalize(item)).join(', ')}
          </Descriptions.Item>
        )}
        {!isNil(condition?.lat) && (
          <Descriptions.Item label="Lat:">
            {LAT_TYPES_TRANSLATIONS(condition?.lat)}
          </Descriptions.Item>
        )}
        {!isEmpty(condition?.law) && (
          <Descriptions.Item label="Law:">
            {condition.law.map((item) => item.toUpperCase()).join(', ')}
          </Descriptions.Item>
        )}
        {!isEmpty(condition?.app_versions) && (
          <Descriptions.Item label="App versions:">
            <>
              <div>{`Min: ${condition.app_versions?.min || EMPTY_VALUE_PLACEHOLDER}`}</div>
              <div>{`Max: ${condition.app_versions?.max || EMPTY_VALUE_PLACEHOLDER}`}</div>
              {!isEmpty(condition?.app_versions?.list) && (
                <div>{`List: ${condition.app_versions.list.map((item) => item).join(', ')}`}</div>
              )}
            </>
          </Descriptions.Item>
        )}
        {!isEmpty(condition?.platforms) && (
          <Descriptions.Item label="Platforms:">
            {condition.platforms.map((item) => capitalize(item)).join(', ')}
          </Descriptions.Item>
        )}
        {!isEmpty(condition?.os_versions) && (
          <Descriptions.Item label="OS versions:">
            <>
              <div>{`Min: ${condition.os_versions?.min || EMPTY_VALUE_PLACEHOLDER}`}</div>
              <div>{`Max: ${condition.os_versions?.max || EMPTY_VALUE_PLACEHOLDER}`}</div>
              {!isEmpty(condition?.os_versions?.list) && (
                <div>{`List: ${condition.os_versions.list.map((item) => item).join(', ')}`}</div>
              )}
            </>
          </Descriptions.Item>
        )}
        {!isEmpty(condition?.users?.types) && (
          <Descriptions.Item label="Users types:">
            {condition.users.types.map((item) => capitalize(item)).join(', ')}
          </Descriptions.Item>
        )}
        {!isEmpty(condition?.users?.list) && (
          <Descriptions.Item label="Users IDs:">
            {condition.users.list.map((item, index) => (
              <div key={item[index]} className='custom-tag'>{item}</div>
            ))}
          </Descriptions.Item>
        )}
        {!isEmpty(condition?.install_dates) && (
          <Descriptions.Item label="Install dates:">
            <>
              {!isEmpty(condition?.install_dates?.min) && (
                <div>
                  Min:
                  <div>{moment.utc(condition.install_dates.min).local().format('DD.MM.YYYY HH:mm:ss')}</div>
                  <Divider style={{ display: 'block' }} className='da-border-color-black-40 da-border-color-dark-80' />
                </div>
              )}
              {!isEmpty(condition?.install_dates?.max) && (
                <div>
                  Max:
                  <div>{moment.utc(condition.install_dates.max).local().format('DD.MM.YYYY HH:mm:ss')}</div>
                  <Divider style={{ display: 'block' }} className='da-border-color-black-40 da-border-color-dark-80' />
                </div>
              )}
              {!isEmpty(condition?.install_dates?.special) && (
                <div>
                  {`Special: ${condition.install_dates.special.map((item) => item).join(', ')}`}
                  <Divider style={{ display: 'block' }} className='da-border-color-black-40 da-border-color-dark-80' />
                </div>
              )}
              {!isEmpty(condition?.install_dates?.list) && (
                <div>
                  List:
                  <div>
                    {condition.install_dates.list.map((datesArr, index) => {
                      return (
                        <div key={datesArr[index]}>
                          {index !== 0 && (
                            <Divider style={{ display: 'block' }} className='da-border-color-black-40 da-border-color-dark-80' />
                          )}
                          <div>{`From: ${!isEmpty(datesArr?.from) ? moment.utc(datesArr.from).local().format('DD.MM.YYYY HH:mm:ss') : EMPTY_VALUE_PLACEHOLDER}`}</div>
                          <div>{`To: ${!isEmpty(datesArr?.to) ? moment.utc(datesArr.to).local().format('DD.MM.YYYY HH:mm:ss') : EMPTY_VALUE_PLACEHOLDER}`}</div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </>
          </Descriptions.Item>
        )}

        {getNotEmptyDescriptionItem(condition?.ab_test?.name, 'AbTest name:')}
        {getNotEmptyDescriptionItem(condition?.ab_test?.group, 'AbTest group:')}
        {getNotEmptyDescriptionItem(condition?.custom_target, 'Custom target:')}
        {!isEmpty(condition?.ip_addresses) && (
          <Descriptions.Item label="Target IPs:">
            {condition.ip_addresses.map((item, index) => (
              <div key={item[index]} className='custom-tag'>{item}</div>
            ))}
          </Descriptions.Item>
        )}
      </Descriptions>
    </CollapsibleCardWr>
  );
};

ConfigurationTargetDetailsConditionsCard.propTypes = {
  index: PropTypes.number.isRequired,
  condition: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default ConfigurationTargetDetailsConditionsCard;
