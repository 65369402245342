import { Col, Row } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import DescriptionValueRow from '../../../../components/DescriptionValueRow';



const ProjectUserDetailsCard = ({ data }) => {
  return (
    <Row justify="space-around" className='description-row'>
      <Col className="da-profile-content-list">
        <ul>
          <DescriptionValueRow
            copyable={!isEmpty(data?.ids?.coreId)}
            title='Core ID:'
            value={data.ids.coreId}
          />
          <DescriptionValueRow
            copyable={!isEmpty(data?.ids?.playerId)}
            title='Player ID:'
            value={data.ids.playerId}
          />
        </ul>
      </Col>
      <Col className="da-profile-content-list">
        <ul>
          <DescriptionValueRow
            copyable={!isEmpty(data?.ids?.advId)}
            title='Adv ID:'
            value={data.ids.advId}
          />
          <DescriptionValueRow
            copyable={!isEmpty(data?.ids?.altId)}
            title='Alt ID:'
            value={data.ids.altId}
          />
        </ul>
      </Col>
    </Row>
  );
};

ProjectUserDetailsCard.propTypes = {
  data: PropTypes.object.isRequired,
};

ProjectUserDetailsCard.defaultProps = {};

export default ProjectUserDetailsCard;
