import React from 'react';
import { Button, Popconfirm, Row, Tag, Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import { EyeOutlined, DeleteOutlined } from '@ant-design/icons';
import isEmpty from 'lodash/isEmpty';
import urlPageUserDetails from '../../../../../urls/urlPageUserDetails';
import urlPageSettingsUserDetails from '../../../../../urls/urlPageSettingsUserDetails';
import { hasRights, Permissions } from '../../../../../const/permissions';
import { getDefaultTableSorting } from '../../../../../lib/getDefaultTableSorting';
import { EMPTY_VALUE_PLACEHOLDER, GLOBAL_SETTINGS_PREFIX, renderDateField } from '../../../../../const/system';
import EllipsisRow from '../../../../../components/EllipsisRow';



export const mkPreparedFormData = (values, spaceKey, spacesOptions) => {
  if (!values.space_ids && spaceKey) {
    const currentSpace = spacesOptions.find((space) => space.key === spaceKey);

    values.space_ids = [ currentSpace.id ];
  }
  return { ...values, name: values.name.trim() };
};


export const getUsersListTableColumns = (handleDeleteUser, settingsScope) => [
  {
    title: 'ID',
    dataIndex: 'id',
    align: 'center',
    width: '7%',
    sorter: true,
    defaultSortOrder: getDefaultTableSorting('id'),
    render: (value) => <div>{value}</div>,
  },
  {
    title: 'Name',
    align: 'center',
    width: '20%',
    render: (item) => {
      return (
        <>
          {hasRights([ Permissions.ADMINISTRATION.USERS.USER_DETAILS.VIEW ]) && !isEmpty(item.name) ? (
            <Link
              to={settingsScope === GLOBAL_SETTINGS_PREFIX
                ? urlPageUserDetails({ userId: item.id })
                : urlPageSettingsUserDetails({ userId: item.id })}
            >
              {item.name}
            </Link>
          ) : (<div>{item.name || EMPTY_VALUE_PLACEHOLDER}</div>)}
        </>
      );
    },
  },
  {
    title: 'Email',
    dataIndex: 'email',
    align: 'center',
    width: '30%',
    render: (value) => <a href={`mailto:${value}`}>{value}</a>,
  },
  ...settingsScope === GLOBAL_SETTINGS_PREFIX ? [
    {
      title: 'Spaces',
      align: 'center',
      width: '15%',
      render: (item) => item?.spaces?.data.length > 0 ? item.spaces.data.map((space) => {
        return (
          <Tag style={{ width: 140, textAlign: 'center' }} key={space.id}>
            <EllipsisRow tooltipPlacement="right" data={space.name} />
          </Tag>
        );
      }) : EMPTY_VALUE_PLACEHOLDER,
    },
  ] : [],
  {
    title: 'Registration date',
    dataIndex: 'created_at',
    align: 'center',
    width: '15%',
    render: (value) => renderDateField(value),
    sorter: true,
    defaultSortOrder: getDefaultTableSorting('created_at'),
  },
  {
    title: 'Last login',
    dataIndex: 'last_login',
    align: 'center',
    width: '15%',
    render: (value) => renderDateField(value),
    sorter: true,
    defaultSortOrder: getDefaultTableSorting('last_login'),
  },
  ...(hasRights([ Permissions.ADMINISTRATION.USERS.USER_DETAILS.VIEW, Permissions.ADMINISTRATION.USERS.USERS_LIST.DELETE_USER ]) ?
    [ {
      title: 'Action',
      key: 'action',
      align: 'center',
      width: '10%',
      render: (item) => {
        return (
          <Row className="da-h-100" align="middle" justify="center">
            {hasRights([ Permissions.ADMINISTRATION.USERS.USERS_LIST.DELETE_USER ]) && (
              <Popconfirm title="Sure to delete?" onConfirm={() => handleDeleteUser(item.id)}>
                <Tooltip placement="top" title='Delete user'>
                  <Button
                    size="small"
                    type='primary'
                    danger
                    className='btn-with-side-margin'
                  >
                    <DeleteOutlined />
                  </Button>
                </Tooltip>
              </Popconfirm>
            )}
            {hasRights([ Permissions.ADMINISTRATION.USERS.USER_DETAILS.VIEW ]) && (
              <Tooltip placement="top" title='User details'>
                <Button size="small" type='primary' className='btn-with-side-margin'>
                  <Link
                    to={settingsScope === GLOBAL_SETTINGS_PREFIX
                      ? urlPageUserDetails({ userId: item.id })
                      : urlPageSettingsUserDetails({ userId: item.id })}
                  >
                    <EyeOutlined />
                  </Link>
                </Button>
              </Tooltip>
            )}
          </Row>
        );
      },
    } ] : []
  ),
];
