import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { Alert, Button, Space } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import urlPageLogin from '../../urls/urlPageLogin';
import { useApp, UserContext } from '../context/AppContext';
import { useLocalStorage } from '../hooks';
import { isFulfilledRequestStatus } from '../../lib/isRequestSuccess';
import { removeNumberFieldsScrolling } from '../../lib/removeNumberFieldsScrolling';
import PageLogin from '.';
import HorizontalLayout from '../../components/Layout/HorizontalLayout';
import Config from '../../config';
import { authData, logoutUser, refreshUser } from './feature/AuthSlice';



const RequireAuth = ({ children }) => {
  const dispatch = useDispatch();
  const { setUserData } = useContext(UserContext);
  const { testParams } = useApp();
  const [ showUnauthorizedCmp, setShowUnauthorizedCmp ] = useState(false);
  const [ isUserUpdated, setIsUserUpdated ] = useState(false);
  const [ appVersion, setAppVersion ] = useLocalStorage('app_version', null);
  const [ _, setLastLocation ] = useLocalStorage('prev_url');

  const url = useMemo(() => window.location.pathname, [ window.location.pathname ]);
  const commitHash = Config.LAST_COMMIT_HASH;

  const { isAuthenticated } = useApp();
  const location = useLocation();
  const data = useSelector(authData);


  useEffect(() => {
    if (!url.endsWith('login')) {
      setLastLocation(url);
    }
    setAppVersion(commitHash);
  }, [ url ]);


  useEffect(() => {
    if (!isEmpty(data)) {
      setUserData(data);
    }
  }, [ data ]);


  useEffect(() => {
    if ((commitHash !== appVersion) && !isEmpty(data)) {
      dispatch(refreshUser(testParams)).then((res) => {
        if (isFulfilledRequestStatus(res)) {
          setAppVersion(commitHash);
          setIsUserUpdated(true);
        }
      });
    }
  }, [ commitHash ]);

  useEffect(() => {
    removeNumberFieldsScrolling();

    const evListenerCallback = () => {
      dispatch(logoutUser());
      setShowUnauthorizedCmp(true);
      setTimeout(() => setShowUnauthorizedCmp(false), 10000);
    };

    const userExpiredEvCallback = () => {
      dispatch(refreshUser(testParams)).then((res) => {
        if (isFulfilledRequestStatus(res)) {
          setIsUserUpdated(true);
        }
      });
    };

    document.addEventListener('UnauthorizedUserEv', evListenerCallback);
    document.addEventListener('isUserDataExpired', userExpiredEvCallback);
    return () => {
      document.removeEventListener('UnauthorizedUserEv', evListenerCallback);
      document.removeEventListener('isUserDataExpired', userExpiredEvCallback);
    };
  }, []);


  return (
    <div className='App'>
      <>
        {!isAuthenticated ? (
          <Navigate
            replace
            to={urlPageLogin()}
            element={<PageLogin isNotAuthorized={showUnauthorizedCmp} />}
            state={{ from: location }}
          />
        ) : (
          <HorizontalLayout>
            {isUserUpdated && (
              <Alert
                message=""
                description="We have some updates for you. Please, refresh the page!"
                type="info"
                action={
                  <Space direction="vertical">
                    <Button
                      size="small"
                      type="primary"
                      onClick={() => {
                        window.location.reload();
                      }}
                    >
                      Refresh
                    </Button>
                  </Space>
                }
              />
            )}
            {children}
          </HorizontalLayout>
        )}
      </>
    </div>
  );
};

RequireAuth.propTypes = {
  children: PropTypes.any.isRequired,
};

export default RequireAuth;
